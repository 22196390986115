.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-wrapper {
  background-color: #330c18;
  min-height: 100vh;
  color: white;
  position: relative;
}

.site-logo {
  /* margin-top: 250px!important;
   */
   /* text-align: center; */
}

.page-wrapper {
  padding-top: 80px!important;
  text-align: center;
  padding-bottom: 40px;
  z-index: 2;
}

.site-logo > h1 {
  font-size: 62px;
}

.sub-title {
  font-size: 28px;
}

.site-desc {
  padding-top: 60px;
  font-size: 22px;
}

.App-link {
  color: #61dafb;
}

.Countdown {
  font-size: 48px;
  text-align: center;
}

.row.section {
  margin-bottom: 60px;
}

span.Countdown-col {
  padding-right: 30px;
}

span.Countdown-col-element>strong {
  padding-right: 10px;
}

.align-center {
  text-align: center;
}

button.mint-btn {
  font-size: 32px;
  padding: 4px 20px;
}

button.mint-btn a,
button.mint-btn a:focus,
button.mint-btn a:active,
button.mint-btn a:hover {
  color: inherit!important;
  text-decoration: none;
}

.nft-art {
  max-width: 340px;
  padding-top: 10px;
}

.vid-wrapper {
  position: relative;
}

.cigar-video {

}

.cigar{
 box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.3),inset -2px -2px 3px 1px rgba(0,0,0,.2);
 padding: 15px;
 height:100%!important;
}

.bg-shadow-black {
 box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.3),inset -2px -2px 3px 1px rgba(0,0,0,.2);
}

.section-2 h1,
.section-3 h1,
.section-4 h1,
.section-5 h1 {
  padding-bottom: 20px;
  text-decoration: underline;
}

.section-1-wrapper {
  min-height: 75vh;
}

.chart-wrapper canvas {
  /* background: linear-gradient(to right, rgb(255, 78, 80), rgb(249, 212, 35)); */
  border-bottom: 0.7px solid #fff;
  border-left: 0.7px solid #fff;
}

.chart-wrapper {
  position: relative;
}

.y-axis-label,
.x-axis-label {
  position: absolute;
}

.y-axis-label {
  left: 60px;
  top: 100px;
}

.x-axis-label { 
  bottom: 30px;
  right: 100px;
}

.cigar-gif {
  /* margin-top: 30px; */
  box-shadow: 1px 3px 4px rgb(255 255 255 / 50%), inset -2px -2px 3px 1px rgb(255 255 255 / 40%);
  max-height: 320px;
}

.section-2,
.section-3,
.section-4,
.section-6  {
  margin-bottom: 80px;
  z-index: 999;
}

p.answer {
  font-size: 18px;
  margin-bottom: 40px;
}

.tm-tile span {
  font-size: 18px;
}

.royalties-desc p {
  font-size: 20px;
}

.soc-icons-wrapper {
  position: absolute;
  left: 50%;
  bottom: 0px;
  margin-left: -120px;
  margin-top: -50px;
  cursor: pointer;
  z-index: 99999999;
  height: 120px;
}
.soc-icons-wrapper a {
  color: unset;
  cursor: pointer;
}

.listed-on label {
  padding-top: 10px;
  /* text-align: center; */
}

.soc-icons-wrapper a:hover {
  color: unset;
}

.soc-icons-wrapper a:active {
  color: unset;
}

.soc-icons-wrapper a:focus {
  color: unset;
}


svg.soc-icon {
  font-size: 60px;
  border: 1px solid #ffffff!important;
  padding: 10px;
  margin-right: 40px;
}

svg.soc-icon.twitter {
  background-color: #1DA1F2;
}

svg.soc-icon.discord {
  background-color: #7289DA;
}

svg.soc-icon.tiktok {
  background-color: #000000;
}

svg.soc-icon.instagram {
  background-color: #bc2a8d;
}
/* timeline - start */

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.tl-container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.tl-container::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -13px;
  background-color: #330d18;
  border: 4px solid #fff;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.tl-left {
  left: 0;
}

/* Place the container to the right */
.tl-right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.tl-left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.tl-right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.tl-right::after {
  left: -13px;
}

/* The actual content */
.tl-content {
  padding: 20px 30px;
  background-color: #330d18;
  position: relative;
  /* border-radius: 6px; */
}

.pixel-border {
  border-style: solid;
  border-width: 4px;
  border-color: #cccccc;
  border-image-slice: 2;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-source: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='6'><path d='M0 2h2v2H0zM2 0h2v2H2zM4 2h2v2H4zM2 4h2v2H2z' fill='%23cccccc' /></svg>");
  position: relative;
}

.tl-content ul {
  list-style: none;
}

.pixel-border::after {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: inset -4px -4px #e6e5e5;
  box-shadow: inset -4px -4px #e6e5e5;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
span.Countdown-col-element>strong {
  padding-right: 0px!important;
}
.king-pic-wrapper {
  padding: 20px;
}
p {
  font-family: 'havana-light';
}
img.cigar {
  height: 220px!important;
}
.chart-wrapper {
  margin-bottom: 80px;
}
.Countdown {
  font-size: 22px!important;
}
div.page-wrapper {
  padding-top: 140px!important;
}
span.Countdown-col {
  /* display: block; */
}
span.Countdown-col {
  padding: 3px!important;
  font-family: 'havana-light';
}
.y-axis-label {
  left: 20px;
}
.section-2, .section-3, .section-4 {
  margin-bottom: 80px!important;
}
/* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

/* Full-width containers */
  .tl-container {
    width: 100%!important;
    padding-left: 70px!important;
    padding-right: 25px!important;
  }

/* Make sure that all arrows are pointing leftwards */
  .tl-container::before {
    left: 60px!important;
    border: medium solid white!important;
    border-width: 10px 10px 10px 0!important;
    border-color: transparent white transparent transparent!important;
  }

/* Make sure all circles are at the same spot */
  .tl-left::after, .tl-right::after {
    left: 18px;
  }

/* Make all right containers behave like the left ones */
  .tl-right {
    left: 0%;
  }
}
/* timeline - end */

img.img-fluid.team-img {
  padding: 20px;
  font-weight: 600;
}

.fade-in {
  animation: fadeIn ease 10s;
  -webkit-animation: fadeIn ease 10s;
  -moz-animation: fadeIn ease 10s;
  -o-animation: fadeIn ease 10s;
  -ms-animation: fadeIn ease 10s;
}
.shake-img {
  animation: shake 5s;
  -webkit-animation: shake ease 10s;
  -moz-animation: shake ease 10s;
  -o-animation: shake ease 10s;
  -ms-animation: shake ease 10s;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-wrapper .container {
  position: relative;
  z-index: 9999;
}

body #playground {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  /* opacity: 0; */
  z-index: 1;
}
body #playground .smoke {
  bottom: -200px;
  background: url(http://www.blog.jonnycornwell.com/wp-content/uploads/2012/07/Smoke10.png) transparent no-repeat 0 0;
  height: 200px;
  width: 200px;
  position: absolute;
  margin: -100px 0 0 -100px;
  background-size: contain;
  opacity: 0;
  animation-name: smoking;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

@keyframes smoking {
  0% {
    opacity: 0.1;
    bottom: -200px;
    transform: scale(1) rotateZ(0);
  }
  20% {
    opacity: 1;
  }
  70% {
    bottom: 65%;
    opacity: 0.8;
    transform: scale(2.7) rotateZ(18deg);
  }
  100% {
    bottom: 100%;
    opacity: 0;
    transform: scale(3) rotateZ(20deg);
  }
}

/* JUMP */
.jump-element-wrapper {
  margin-bottom: 80px;
}

.modal-dialog {
  max-width: 800px!important;
  margin: 30px auto!important;
}

.modal-body {
  position:relative!important;
  padding:0px!important;
}
.btn-close {
  position:absolute;
  right: -16px;
  top: -16px;
  z-index:999;
  font-size:2rem;
  font-weight: normal;
  color:#fff;
  opacity:1!important;
  background-color: #fff!important;
  width: 0.75em!important;
  height: 0.75em!important;
}

svg.play-btn {
  font-size: 60px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: #FF0000;
  animation: beat .25s infinite alternate;
	transform-origin: center;
  cursor: pointer;
}

@keyframes beat{
	to { transform: scale(1.4); }
}

.arrow-up {
  -webkit-animation: bounce2 1s ease infinite;
  animation: bounce2 1s ease infinite;
}

.j-e-1 {
  -webkit-animation: bounce2 2.5s ease infinite;
  animation: bounce2 2.5s ease infinite;
}
.j-e-2 {
  -webkit-animation: bounce2 3s ease infinite;
  animation: bounce2 3s ease infinite;
}
.j-e-3 {
  -webkit-animation: bounce2 3.5s ease infinite;
  animation: bounce2 3.5s ease infinite;
}
.j-e-4 {
  -webkit-animation: bounce2 4s ease infinite;
  animation: bounce2 4s ease infinite;
}
.j-e-5 {
  -webkit-animation: bounce2 4.5s ease infinite;
  animation: bounce2 4.5s ease infinite;
}
.j-e-6 {
  -webkit-animation: bounce2 5s ease infinite;
  animation: bounce2 5s ease infinite;
}
.j-e-7 {
  -webkit-animation: bounce2 2.5s ease infinite;
  animation: bounce2 2.5s ease infinite;
}
.j-e-8 {
  -webkit-animation: bounce2 3s ease infinite;
  animation: bounce2 3s ease infinite;
}
.j-e-9 {
  -webkit-animation: bounce2 3.5s ease infinite;
  animation: bounce2 3.5s ease infinite;
}
.j-e-10 {
  -webkit-animation: bounce2 4s ease infinite;
  animation: bounce2 4s ease infinite;
}
.j-e-11 {
  -webkit-animation: bounce2 4.5s ease infinite;
  animation: bounce2 4.5s ease infinite;
}
.j-e-12 {
  -webkit-animation: bounce2 5s ease infinite;
  animation: bounce2 5s ease infinite;
}
.j-e-13 {
  -webkit-animation: bounce2 5.5s ease infinite;
  animation: bounce2 5.5s ease infinite;
}
.j-e-14 {
  -webkit-animation: bounce2 6s ease infinite;
  animation: bounce2 6s ease infinite;
}
.j-e-15 {
  -webkit-animation: bounce2 6.5s ease infinite;
  animation: bounce2 6.5s ease infinite;
}
.j-e-16 {
  -webkit-animation: bounce2 7s ease infinite;
  animation: bounce2 7s ease infinite;
}
.j-e-17 {
  -webkit-animation: bounce2 7.5s ease infinite;
  animation: bounce2 7.5s ease infinite;
}
.j-e-18 {
  -webkit-animation: bounce2 8s ease infinite;
  animation: bounce2 8s ease infinite;
}
.j-e-19 {
  -webkit-animation: bounce2 8.5s ease infinite;
  animation: bounce2 8.5 ease infinite;
}
.j-e-20 {
  -webkit-animation: bounce2 9s ease infinite;
  animation: bounce2 9s ease infinite;
}


.jump-element-wrapper img {
  max-width: 5%;
}

@keyframes bounce2 {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}